import React from 'react'

const Alert = ({img, message}) => {
  return (
    <>
      <div className="alertSectionCont">
        <div className="alertSection d-flex align-items-center">
          <img src={img} id="alertImg" alt="" />
          <p className="mb-0" id="alertMsg">{message}</p>
        </div>
      </div>
    </>
  )
}

export default Alert