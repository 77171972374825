// export const HOST = "http://localhost:5000"
export const HOST = "https://ribbitmap-backend.vercel.app"
export const getRandomLatLong = () => {
    // Array of genuine lat/long pairs between the United States and Europe
    const coordinates = [
        { lat: 40.712776, long: -74.005974 }, // New York, USA
        { lat: 34.052235, long: -118.243683 }, // Los Angeles, USA
        { lat: 51.507351, long: -0.127758 }, // London, UK
        { lat: 48.856613, long: 2.352222 }, // Paris, France
        { lat: 52.520008, long: 13.404954 }, // Berlin, Germany
        { lat: 37.774929, long: -122.419418 }, // San Francisco, USA
        { lat: 41.902782, long: 12.496366 }, // Rome, Italy
        { lat: 40.416775, long: -3.703790 }, // Madrid, Spain
        { lat: 35.689487, long: 139.691711 }, // Tokyo, Japan
        { lat: 55.755825, long: 37.617298 }, // Moscow, Russia
        { lat: 60.169857, long: 24.938379 }, // Helsinki, Finland
        { lat: 39.904202, long: 116.407394 }, // Beijing, China
        { lat: 38.722252, long: -9.139337 }, // Lisbon, Portugal
        { lat: 50.110924, long: 8.682127 }, // Frankfurt, Germany
        { lat: 48.208176, long: 16.373819 }, // Vienna, Austria
        { lat: 59.329323, long: 18.068581 }, // Stockholm, Sweden
        { lat: 41.385063, long: 2.173404 }, // Barcelona, Spain
        { lat: 40.730610, long: -73.935242 }, // New York City, USA
        { lat: 47.497913, long: 19.040236 }, // Budapest, Hungary
        { lat: 53.349805, long: -6.260310 }, // Dublin, Ireland
        { lat: 45.465421, long: 9.185924 }, // Milan, Italy
        { lat: 52.229676, long: 21.012229 }, // Warsaw, Poland
        { lat: 59.913868, long: 10.752245 }, // Oslo, Norway
        { lat: 43.769562, long: 11.255814 }, // Florence, Italy
        { lat: 50.075539, long: 14.437800 }, // Prague, Czech Republic
        { lat: 46.947976, long: 7.447447 }, // Bern, Switzerland
        { lat: 52.370216, long: 4.895168 }, // Amsterdam, Netherlands
        { lat: 48.135125, long: 11.581981 }, // Munich, Germany
        { lat: 39.739235, long: -104.990250 }, // Denver, USA
        { lat: 42.360081, long: -71.058884 }, // Boston, USA
        { lat: 45.815010, long: 15.981919 }, // Zagreb, Croatia
        { lat: 54.687157, long: 25.279652 }, // Vilnius, Lithuania
        { lat: 55.676098, long: 12.568337 }, // Copenhagen, Denmark
        { lat: 35.227085, long: -80.843124 }, // Charlotte, USA
        { lat: 33.448376, long: -112.074036 }, // Phoenix, USA
        { lat: 29.760427, long: -95.369804 }, // Houston, USA
        { lat: 25.761681, long: -80.191788 }, // Miami, USA
        { lat: 38.907192, long: -77.036873 }, // Washington, USA
        { lat: 37.774929, long: -122.419416 }, // San Francisco, USA
        { lat: 51.165691, long: 10.451526 }, // Germany
        { lat: 41.878113, long: -87.629799 }, // Chicago, USA
        { lat: 32.715736, long: -117.161087 }, // San Diego, USA
        { lat: 45.421530, long: -75.697193 }, // Ottawa, Canada
        { lat: 49.282730, long: -123.120735 }, // Vancouver, Canada
        { lat: 43.651070, long: -79.347015 }, // Toronto, Canada
        { lat: 64.963051, long: -19.020835 }, // Reykjavik, Iceland
        { lat: 60.173324, long: 24.941025 }, // Helsinki, Finland
        { lat: 52.486244, long: -1.890401 }, // Birmingham, UK
        { lat: 45.464211, long: 9.191383 }, // Milan, Italy
    ];

    // Randomly select a coordinate from the array
    const randomCoordinate = coordinates[Math.floor(Math.random() * coordinates.length)];

    // Return the result with 6 decimal places
    return {
        lat: randomCoordinate.lat.toFixed(4),
        long: randomCoordinate.long.toFixed(4)
    };
}


export const formatAmount = (amount) => {
    const num = Number(amount); // Convert amount to a number
    if (isNaN(num)) return amount; // If it's not a number, return it as is

    if (num >= 1_000_000_000) {
        return (num / 1_000_000_000).toFixed(1) + 'B'; // Billions
    } else if (num >= 1_000_000) {
        return (num / 1_000_000).toFixed(1) + 'M'; // Millions
    } else if (num >= 1_000) {
        return (num / 1_000).toFixed(1) + 'K'; // Thousands
    } else {
        return num.toString(); // Less than 1,000, return as is
    }
}
