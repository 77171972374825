import './App.css';
import Mapp from "./Map";
import Header from "./components/Header"

function Main() {
  return (
    <div className="App">
      <Header />
      <Mapp />
    </div>
  );
}

export default Main;
